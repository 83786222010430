import React, { useEffect, useState } from "react";
import { SRLWrapper } from "simple-react-lightbox";
import Masonry from "react-masonry-css";
import "../css/gallery.scss";
import useWindowDimensions from "../helpers/useWindowDimensions";
import client from "../helpers/contentfulClient";
import ImageFadeIn from "react-image-fade-in";
import { Redirect } from "react-router";

export default function Gallery(state) {
  const galleryLink = state.props.match.params.galleryName
    ? state.props.match.params.galleryName
    : "illustration";
  const [galleryContent, setGalleries] = useState({ items: [] });
  const { width } = useWindowDimensions();
  const [breakPoints, setBreakPoints] = useState(3);
  const [error, setError] = useState(false);

  const GridElement = ({ gallery }) => (
    <div className="galleryItem">
      <ImageFadeIn
        className="galleryImg"
        src={gallery.imgUrl}
        alt={gallery.title}
      />
    </div>
  );

  const GridVideoElement = ({ gallery }) => (
    <div className="galleryItem galleryVideo">
      <video controls>
        <source src={gallery.videoUrl} type="video/mp4" />
      </video>
    </div>
  );

  const options = {
    buttons: {
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: true,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
    },
  };

  useEffect(() => {
    const handleResize = () => {
      if (width < 767) {
        setBreakPoints(1);
      } else {
        setBreakPoints(3);
      }
    };

    const galleryApi = async () => {
      const entries = await client.getEntries({
        content_type: "contentItem",
        order: "fields.sortOrder",
        "fields.gallery.sys.contentType.sys.id": "gallery",
        "fields.gallery.fields.galleryLink": `${galleryLink}`,
      });
      if (entries.items.length === 0) {
        setError(true);
        return;
      }
      entries.items.forEach((item) => {
        try {
        let asset = entries.includes.Asset.find(
          (asset) => asset.sys.id === item.fields.asset?.sys.id
        );
        if(!asset || !asset?.fields || !asset?.fields?.file) {
          console.error(`no fields for item ${item.fields.asset?.sys.id ?? item.sys.id}`);
          item.imgUrl = false;
          item.videoUrl = false;
          return;
        }
        if (asset.fields.file.contentType.includes("image"))
          item.imgUrl = asset ? asset.fields.file.url : false;
        if (asset.fields.file.contentType.includes("video"))
          item.videoUrl = asset ? asset.fields.file.url : false;
        } catch (err) {
          console.error(err);
          item = undefined;
        }
      });
      setGalleries(entries);
    };
    galleryApi();
    window.addEventListener("resize", handleResize());
  }, [galleryLink, width]);
  if (error) {
    return <Redirect to="/error" />;
  }

  return (
    <div div className="gallery">
      <div className="gallery-container">
        {galleryContent.items.map(
          (gallery, index) =>
            gallery.videoUrl && (
              <GridVideoElement gallery={gallery} key={index} />
            )
        )}
        <SRLWrapper options={options}>
          <Masonry
            breakpointCols={breakPoints}
            className="gallery-grid"
            columnClassName="gallery-grid_column"
          >
            {galleryContent.items.map(
              (gallery, index) =>
                gallery.imgUrl && <GridElement gallery={gallery} key={index} />
            )}
          </Masonry>
        </SRLWrapper>
      </div>
    </div>
  );
}
