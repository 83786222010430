import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import Gallery from "./components/gallery";
import Contact from "./components/Contact";
import Page from "./components/page";
import ErrorPage from "./components/ErrorPage";
import logo from "./assets/logo.svg";
import "./App.css";
import { AppContext } from "./helpers/contextLib";
import client from "./helpers/contentfulClient";
import ImageFadeIn from "react-image-fade-in";

function App() {
  const [galleryContent, setGalleries] = React.useState({ items: [] });
  const [burgerOpen, setBurgerOpen] = React.useState("");

  function showGallery(props) {
    return <Gallery props={props} />;
  }

  function showContact() {
    return <Contact />;
  }

  function showError() {
    return <ErrorPage />;
  }

  function showPage(props) {
    return <Page props={props} />;
  }
  const toggleBurger = () => {
    if (burgerOpen === "") {
      setBurgerOpen("open");
    } else {
      setBurgerOpen("");
    }
  };

  React.useEffect(() => {
    const galleryApi = async () => {
      const entries = await client.getEntries({ content_type: "gallery" });
      setGalleries(entries);
    };
    galleryApi();
  }, []);

  return (
    <Router>
      <SimpleReactLightbox>
        <div className="App">
          <div className="Container">
            <div className="App-header">
              <div
                className="navLogoDiv"
                onClick={() => {
                  window.location = "/";
                }}
              >
                <ImageFadeIn className="navLogo" src={logo} alt="Gee!" />
              </div>
              <div
                className={`BurgerButton ${burgerOpen}`}
                onClick={toggleBurger}
              >
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </div>
              <div className={`navBar ${burgerOpen}`}>
                <div className="navElement">
                  <label
                    className="navLink"
                    onClick={() => {
                      window.location = "/page/about";
                    }}
                  >
                    ABOUT
                  </label>
                </div>
                {galleryContent.items.map((gallery, index) => (
                  <div className="dynamicNav">
                    <div className="bulletPoint" key={`index2+${index}`}>
                      &bull;
                    </div>
                    <div className="navElement" key={index}>
                      <label
                        className="navLink"
                        onClick={() => {
                          window.location = `/${gallery.fields.galleryLink}`;
                        }}
                      >
                        {gallery.fields.galleryName.toUpperCase()}
                      </label>
                    </div>
                  </div>
                ))}
                <div className="bulletPoint">&bull;</div>
                <div className="navElement">
                  <label
                    className="navLink"
                    onClick={() => {
                      window.location = "/contact";
                    }}
                  >
                    CONTACT
                  </label>
                </div>
              </div>
            </div>
            <div className="divider"></div>
            <AppContext.Provider>
              {burgerOpen !== "open" && (
                <Switch>
                  <Route path="/page/:pageName" component={showPage} />
                  <Route path="/error" component={showError} />
                  <Route path="/contact" component={showContact} />
                  <Route path="/:galleryName" component={showGallery} />
                  <Route path="/" component={showGallery} />
                </Switch>
              )}
            </AppContext.Provider>
          </div>
        </div>
      </SimpleReactLightbox>
    </Router>
  );
}

export default App;
