import React, { useState, useEffect } from "react";
import "../css/contact.css";
import client from "../helpers/contentfulClient";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export default function Contact() {
  const apiRoute = `https://fd0835lodj.execute-api.eu-west-2.amazonaws.com/`;
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");
  const [pageContent, setPageContent] = useState(<div></div>);
  const [pageHeader, setPageHeader] = useState();

  function ValidateEmail(mail) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    );
  }

  const handleChange = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    fields[name] = value;
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = (event) => {
    setSuccess("");
    setErrors("");
    event.preventDefault();

    if (!fields || !fields.email || !fields.name || !fields.message) {
      setErrors("Please ensure all manditory fields are entered.");
      return;
    }

    if (!ValidateEmail(fields.email)) {
      setErrors("Please use a valid email address.");
      return;
    }
    fetch(apiRoute, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(fields),
    })
      .then((response) => {
        if (response.status !== 200) {
          setErrors(
            "There was a problem sending your message, try email me directly!"
          );
          return;
        }
        setSuccess("Message Sent, I'll get back to you soon!");
      })
      .catch((error) => {
        console.log(error);
        setErrors(
          `There was a problem sending your message, try email me directly!`
        );
      });
  };

  useEffect(() => {
    setLoading(true);
    const pageApi = async () => {
      const entries = await client.getEntries({
        content_type: "contactPage",
      });

      if (entries.items[0].fields.contactMessaging) {
        setPageContent(entries.items[0].fields.contactMessaging);
      }
      if (entries.items[0].fields.formHeader) {
        setPageHeader(entries.items[0].fields.formHeader);
      }
      setLoading(false);
    };
    pageApi();
  }, []);

  return (
    !loading && (
      <div className="contact-container">
        <h1>{pageHeader}</h1>
        <div className="form-container">
          <div
            className="form-message"
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(pageContent),
            }}
          ></div>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              className="textInput"
              placeholder="Name *"
              value={fields.name}
              onChange={handleChange}
            />
            <input
              type="text"
              name="email"
              className="textInput"
              placeholder="Email *"
              value={fields.email}
              onChange={handleChange}
            />
            <input
              type="text"
              name="subject"
              className="textInput"
              placeholder="Subject"
              value={fields.subject}
              onChange={handleChange}
            />
            <textarea
              name="message"
              className="textAreaInput"
              placeholder="Message *"
              value={fields.message}
              onChange={handleChange}
            />
            <button className="submitButton" type="submit">
              Send
            </button>
            <label className="error">{errors}</label>
            <label className="success">{success}</label>
          </form>
        </div>
      </div>
    )
  );
}
