import React, { useEffect, useState } from "react";
import "../css/error.css";
import client from "../helpers/contentfulClient";
import ImageFadeIn from "react-image-fade-in";

export default function ErrorPage() {
  const [loading, setLoading] = useState(true);
  const [errorHeader, setErrorHeader] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [errorButton, setErrorButton] = useState();
  const [pageImage, setPageImage] = useState();

  useEffect(() => {
    setLoading(true);
    const pageApi = async () => {
      const entries = await client.getEntries({
        content_type: "errorPage",
      });
      if (entries.includes.Asset[0].fields) {
        setPageImage(entries.includes.Asset[0].fields.file.url);
      }
      if (entries.items[0].fields.errorPageHeader) {
        setErrorHeader(entries.items[0].fields.errorPageHeader);
      }
      if (entries.items[0].fields.errorMessage) {
        setErrorMessage(entries.items[0].fields.errorMessage);
      }
      if (entries.items[0].fields.errorButtonText) {
        setErrorButton(entries.items[0].fields.errorButtonText);
      }
      setLoading(false);
    };
    pageApi();
  }, []);

  return (
    !loading && (
      <div className="Error">
        <div className="Error-container">
          <div className="pageImageContainer">
            <ImageFadeIn className="pageImg" src={pageImage} alt="error" />
          </div>
          <h3>{errorHeader}</h3>
          {errorMessage}
        </div>
        <div class="about-divider"></div>
        <a href="/" title="Return to the homepage" class="return-button">
          {errorButton}
        </a>
      </div>
    )
  );
}
