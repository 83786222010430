import React, { useEffect, useState } from "react";
import ImageFadeIn from "react-image-fade-in";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import "../css/page.css";
import client from "../helpers/contentfulClient";
import { Redirect } from "react-router";

export default function Page(state) {
  const pageName = state.props.match.params.pageName;
  const [loading, setLoading] = useState(true);
  const [pageContent, setPageContent] = useState(<div></div>);
  const [pageImage, setPageImage] = useState();
  const [resumeUrl, setResumeUrl] = useState();
  const [error, setError] = useState(false);
  const [styleObj, setstyleObj] = useState({ fontSize: 30 });

  useEffect(() => {
    setLoading(true);
    const pageApi = async () => {
      const entries = await client.getEntries({
        content_type: "localeAssets",
        "fields.title[match]": `${pageName}`,
      });

      if (entries.items.length === 0) {
        setError(true);
        return;
      }

      if (entries.items[0].fields.paragraph) {
        setPageContent(entries.items[0].fields.paragraph);
      }
      if (entries.items[0].fields.fontsize) {
        setstyleObj({ fontSize: entries.items[0].fields.fontsize });
      }
      const img = entries.includes.Asset.find(
        (asset) => asset.sys.id === entries.items[0].fields.image.sys.id
      );
      const resume = entries.includes.Asset.find(
        (asset) => asset.sys.id === entries.items[0].fields.resume.sys.id
      );
      if (img) {
        setPageImage(img.fields.file.url);
      }
      if (resume) {
        setResumeUrl(resume.fields.file.url);
      }
      setLoading(false);
    };
    pageApi();
  }, [pageName]);

  if (error) {
    return <Redirect to="/error" />;
  }

  return (
    !loading && (
      <div className="article">
        <div className="page-container">
          <div className="pageImageContainer">
            <ImageFadeIn className="pageImg" src={pageImage} alt={pageName} />
          </div>
          <div
            className="page-content"
            style={styleObj}
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(pageContent),
            }}
          />
        </div>
        <div class="about-divider"></div>
        <a
          href={resumeUrl}
          title="Download Holly Gee C.V."
          download="Holly_GEE_RESUME.pdf"
          class="resume-button"
        >
          RESUME/CV
        </a>
      </div>
    )
  );
}
